/**
 * @typedef {import('@sentry/types').Event} SentryEvent
 * @typedef {import('@sentry/types').EventHint} SentryEventHint
 */

import React, { lazy, Suspense } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import '../scss/style.scss';
import '../scss/core/_dropdown-menu-right.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// eslint-disable-next-line import/no-unresolved
import { register } from 'swiper/element/bundle';
import { createBrowserHistory } from 'history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './views/Pages/Login';
import TableLoader from './components/Loader/TableLoader';
import { LoginAdmin } from './views/Pages/LoginAdmin';
import { StateProvider } from './Context';
import { FallbackError } from './components/FallbackError';
import { findRouteName } from './components/Breadcrumb/Breadcrumb';

const Full = lazy(() => import('./containers/Full'));
const SalesList = lazy(() => import('./views/Pages/CallCenter'));
const NewSale = lazy(() => import('./views/Pages/CallCenter/Sales/New'));

const SentryRoute = Sentry.withSentryRouting(Route);
const history = createBrowserHistory();
const { version } = require('../package.json');
// eslint-disable-next-line no-undef
gtag('config', 'G-KMKQPK50LS', {
  send_page_view: false,
});

history?.listen(() => {
  console.log('# track: ', window.location.href);
  // eslint-disable-next-line no-undef
  console.log('# window?.gtag: ', gtag);
  // eslint-disable-next-line no-undef
  gtag('event', 'page_view', {
    page_location: window.location.href,
    page_title: findRouteName(window.location.href.split('#')[1]),
  });
});
const containerStyle = {
  zIndex: 1999,
  fontWeight: 'bold',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

Sentry.init({
  dsn: 'https://6241f6028eba666363bd6b5e3138eb46@o212735.ingest.sentry.io/5205291',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: ['https://api-gerencial.opdv.com.br'],
      networkRequestHeaders: ['X-Custom-Header'],
      networkResponseHeaders: ['X-Custom-Header'],
    }),
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  release: version,

  /**
   * @param {SentryEvent} event
   * @param {SentryEventHint} hint
   * @returns {SentryEvent|null}
   */
  beforeSend(event, hint) {
    if (
      process.env.ENVIRONMENT === 'DEV' ||
      process.env.ENVIRONMENT === 'DEV2' ||
      process.env.ENVIRONMENT === 'HOMOLOG'
    ) {
      return null;
    }
    if (event.exception && hint.originalException) {
      const exception = hint.originalException;

      // não registrando erros com status code de 401-499
      if (exception.response && exception.response.status === 401) {
        return null;
      }
    }

    return event;
  },
});

register();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <HashRouter>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      style={containerStyle}
    />
    <StateProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense
          fallback={
            <div>
              <TableLoader />
            </div>
          }
        >
          <Sentry.ErrorBoundary fallback={<FallbackError />}>
            <Switch>
              <SentryRoute
                path="/callcenter"
                exact
                name="Callcenter"
                component={SalesList}
              />
              <SentryRoute
                path="/callcenter/new"
                exact
                name="Novo Pedido"
                component={NewSale}
              />
              <SentryRoute
                path="/callcenter/new/:orderId"
                exact
                name="Novo Pedido"
                component={NewSale}
              />
              <SentryRoute
                exact
                path="/login"
                name="Login Page"
                component={Login}
              />
              <SentryRoute
                exact
                path="/login/admin"
                name="Login Page"
                component={LoginAdmin}
              />
              <SentryRoute path="/" name="Home" component={Full} />
            </Switch>
          </Sentry.ErrorBoundary>
        </Suspense>
      </QueryClientProvider>
    </StateProvider>
  </HashRouter>
);
