/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Route, Link } from 'react-router-dom';
import {
  Breadcrumb as BreadchumbBootstrap,
  BreadcrumbItem,
  Button,
} from 'reactstrap';
import { getUserTraining } from '../../api/user';

import { routesNames } from '../../routes';
import { useInternalContext } from '../../Context';
import { ModalVideoTutorial } from '../ModalVideoTutorial';

/** @param {string} url */
const findVideoIdOnYoutubeUrl = url => {
  if (!url) return '';
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

/** @param {string} pathname */
const isCallcenterCreateOrderPage = pathname => pathname === '/callcenter/new';

const getVideoUrl = ({ currentMenu, pathname }) =>
  isCallcenterCreateOrderPage(pathname)
    ? 'https://youtu.be/b5gIi8pI_7o'
    : currentMenu?.urlVideo;

/** @param {string} url */
export const findRouteName = url => {
  console.log('# route url: ', url);
  const splited = url.split('/');
  if (splited[splited.length - 1].match(/\d+/g)) {
    console.log('# route: ', routesNames[`${url.replace(/[0-9]/g, '')}:id`]);
    return routesNames[`${url.replace(/[0-9]/g, '')}:id`];
  }
  console.log('# route: ', routesNames[url]);
  return routesNames[url];
};

/** @param {string} pathname */
const getPaths = pathname => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

export const BreadcrumbsItem = ({ match }) => {
  const routeName = findRouteName(match.url);

  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={match.url || ''}>{routeName}</Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

export const Breadcrumbs = ({ location: { pathname, search } }) => {
  const { flatMenus, setRun, run } = useInternalContext();

  const [modalVideoIsOpen, setModalVideoIsOpen] = useState(false);
  const [userTraining, setUserTraining] = useState(false);

  const paths = getPaths(pathname);
  const routeName = findRouteName(pathname);
  const items = paths.map(path => (
    <Route key={path} path={path} component={BreadcrumbsItem} />
  ));

  const currentMenu = flatMenus.find(
    menu => menu.url === pathname || menu.url === search.split('tab=')[1]
  );

  const baseUrl = currentMenu?.urlBase;

  const videoUrl = getVideoUrl({ currentMenu, pathname });

  const videoId = findVideoIdOnYoutubeUrl(videoUrl);

  useEffect(() => {
    async function fetchUserTraining() {
      const { didOnBoarding } = await getUserTraining(pathname);
      if (didOnBoarding === 'YES' || (didOnBoarding === 'NO' && run)) {
        setUserTraining(true);
      } else {
        setUserTraining(false);
      }
    }
    fetchUserTraining();
  }, [pathname, run]);

  return (
    <>
      <ModalVideoTutorial
        title={routeName}
        videoId={videoId}
        isOpen={modalVideoIsOpen}
        setIsOpen={setModalVideoIsOpen}
      />

      <BreadchumbBootstrap style={{ display: 'flex', alignItems: 'center' }}>
        {items}
        <Button
          onClick={() => {
            window.open('https://opdv.com.br/blog/', '_blank');
          }}
          color="primary"
          style={{ marginLeft: 'auto', fontWeight: 600 }}
        >
          Novidades OPDV
        </Button>
        {userTraining && (
          <div>
            <Button
              color="primary"
              style={{ borderRadius: 5, width: '2.6rem' }}
              onClick={() => setRun(true)}
            >
              <i className="fa-solid fa-info" />
            </Button>
          </div>
        )}
        {baseUrl && (
          <div style={{ marginLeft: 5 }}>
            <Button
              color="primary"
              style={{ borderRadius: 5 }}
              href={baseUrl}
              target="_blanck"
            >
              <i className="fa-solid fa-book-open" />
            </Button>
          </div>
        )}
        {videoId && (
          <>
            <div style={{ marginLeft: 5 }} data-test-id="video-page">
              <Button
                color="primary"
                style={{ borderRadius: 5 }}
                onClick={() => setModalVideoIsOpen(true)}
              >
                <i className="fa-solid fa-video" />
              </Button>
            </div>
          </>
        )}
      </BreadchumbBootstrap>
    </>
  );
};

export default props => (
  <div>
    <Route path="/:path" component={Breadcrumbs} {...props} />
  </div>
);
