/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import {
  Container,
  Row,
  Col,
  CardGroup,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  InputGroup,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import Storage from '../../Storage';
import { useInternalContext } from '../../../Context';

const mainColor =
  process.env.ENVIRONMENT === 'PRODUCTION' ? '#2b2462' : '#622424';

const Login = () => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tokenCaptcha, setTokenCaptcha] = useState(null);
  const { loginUserManager } = useInternalContext();

  const closeChat = () => {
    if (window.fcWidget) {
      window.fcWidget.hide();
      window.fcWidget.close();
    }
    if (window.comTTChat && document.getElementById('comTomTicketChatWidget')) {
      document.body.removeChild(
        document.getElementById('comTomTicketChatWidget')
      );
    }
    const script = document.createElement('script');
    script.textContent = `
        try{
          if (iFoodWidget){
            iFoodWidget.hide()
          }
        }catch(e){

        }

    `;
    script.id = 'ifood_chat';
    document.body.appendChild(script);
  };

  useEffect(() => {
    Storage.setItem('token', null);
    Storage.clear();
    closeChat();
  }, []);

  const handleLogin = async e => {
    e.preventDefault();
    setIsLoading(true);
    await loginUserManager({
      nameAdm: formData.nameAdm,
      userLogin: formData.login,
      password: formData.password,
      tokenCaptcha,
    });
    setIsLoading(false);
  };

  const handleChange = e => {
    const data = { ...formData };
    data[e.target.name] = e.target.value;
    console.log('# data: ', data);
    setFormData(data);
  };

  return (
    <div className="app flex-row align-items-center">
      <Container>
        {process.env.ENVIRONMENT !== 'PRODUCTION' && (
          <h1 style={{ textAlign: 'center' }}>Ambiente de Homologação</h1>
        )}
        <form onSubmit={handleLogin}>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup className="mb-4">
                <Card className="p-4" style={{ backgroundColor: mainColor }}>
                  <CardHeader
                    style={{
                      textAlign: 'center',
                      backgroundColor: mainColor,
                    }}
                  >
                    <img
                      src="/img/logo.png"
                      alt="Logo Opdv"
                      style={{ width: 100 }}
                    />
                  </CardHeader>
                  <CardBody style={{ backgroundColor: mainColor }}>
                    <h1 style={{ color: 'white' }}>Login</h1>
                    <p style={{ color: 'white' }} className="text-muted">
                      Entre na sua conta.
                    </p>
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-user" />
                        </span>
                      </div>
                      <Input
                        type="text"
                        placeholder="Loja"
                        onChange={handleChange}
                        name="nameAdm"
                      />
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-user" />
                        </span>
                      </div>
                      <Input
                        type="text"
                        placeholder="Login"
                        onChange={handleChange}
                        name="login"
                      />
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="icon-lock" />
                        </span>
                      </div>
                      <Input
                        type="password"
                        placeholder="Password"
                        onChange={handleChange}
                        name="password"
                      />
                    </InputGroup>
                    <Row>
                      <Col xs="12">
                        <ReCAPTCHA
                          sitekey={process.env.GOOGLE_SITEKEY_CAPTCHA}
                          onChange={data => setTokenCaptcha(data)}
                        />
                      </Col>
                      <Col xs="12" style={{ marginTop: 15 }}>
                        <Button
                          color="primary"
                          type="submit"
                          className="px-4"
                          style={{
                            color: 'white',
                          }}
                          disabled={!tokenCaptcha || isLoading}
                        >
                          Entrar
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default Login;
