import { generateRequest } from './index';

export const loginUser = async (nameAdm, login, password, tokenCaptcha) => {
  const request = await generateRequest();
  return request.post('login', { nameAdm, login, password, tokenCaptcha });
};

/**
 *
 * @param {number} merchantId
 * @returns AxiosRequest
 */
export const changeMerchant = async merchantId => {
  return generateRequest().post(`/login/change/${merchantId}`);
};

export const loginUserAdmin = async ({ nameAdm, token }) => {
  const request = await generateRequest();
  return request.post('login/admin', { nameAdm, token });
};

export const verifyAction = async ({ login, password, actionId }) => {
  const request = await generateRequest();
  const resp = await request.post(`action/verify`, {
    login,
    password,
    actionId,
  });
  if (resp.status === 200) {
    return resp.data;
  }
  return null;
};
